import { formatCurrency } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { ReportViewerComponent } from "app/+alliant/components/data-query/report-viewer/report-viewer.component";
import { isEmpty } from "lodash";
import moment from "moment";
import { ICriteria, ISGlobal, ReportNameSpace } from "reporting/services/report.models";
import { ReportService } from "reporting/services/report.service";
import { DateValueChangedEvent } from "rl-common/components/char-data/controls/date-edit-control.component";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { CommonDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/common-data-select.strategy";
import { GridColumn } from "rl-common/components/grid/grid.models";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { ICharacteristicTemplate, ICharacteristicType, ICharDataMoneyValueNew, IRecordTitle } from "rl-common/models";
import { ISourceField } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { ModalService } from "rl-common/services/modal.service";
import { IOneConfigWorkflowAction, IOneConfigWorkflowStep } from "rl-common/services/one-config/one-config.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ProgressService } from "rl-common/services/progress.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { NumberUtil } from "rl-common/utils/number.util";
import { Subscription } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { SearchService } from "../../../common/services/search/search.service";
import { IAHNReport, IAuditHistoryNavigatorCriteriaRequest, IAuditHistoryNavigatorResponse } from "./audit-history-navigator.models";
import { AuditHistoryNavigatorService } from "./audit-history-navigator.service";

@Component({
	selector: "rl-audit-history-navigator",
	templateUrl: "./audit-history-navigator.component.html",
	styleUrls: ["./audit-history-navigator.component.scss"],
	providers: [AuditHistoryNavigatorService]
})
export class AuditHistoryNavigatorComponent implements OnInit, OnDestroy {

	@ViewChild(ReportViewerComponent) reportViewer: ReportViewerComponent;


	public isParamAreaOpen = false;

	public openTo = "right";
	public isCollapsed = false;
	public sidePanelWidthOpen = 340; //'340px';
	public sidePanelWidthClosed = 42; //'42px';

	public sidePanelWidth: number = this.isCollapsed ? this.sidePanelWidthClosed : this.sidePanelWidthOpen;
	translateXValue = "translateX(" + (this.sidePanelWidth - this.sidePanelWidthOpen) + "px )";

	private readonly _subs: Subscription[] = [];

	criteriaRequest: IAuditHistoryNavigatorCriteriaRequest;

	selectedCharType: CharTypeId = -1;
	selectedTemplateId: number = -1;
	selectedTemplate: ICharacteristicTemplate = null;
	selectedRecordIds: number[] = [];
	selectedRecords: IRecordTitle[] = [];
	selectedUsers: IRecordTitle[] = [];
	templates: ICharacteristicTemplate[];
	selectedFields: ISourceField[] = [];

	workflowOptionsReady: boolean = false;
	availableWorkflowStatuses: IOneConfigWorkflowStep[] = [];
	availableWorkflowActions: IOneConfigWorkflowAction[] = [];
	nullStatus: IOneConfigWorkflowStep = { stepId: null, stepName: "Select" } as IOneConfigWorkflowStep;
	nullWorkflowAction = { actionId: null, actionName: "Select" } as IOneConfigWorkflowAction;

	reportNameSpace = ReportNameSpace.AHNReport;
	selectedSavedCriteria: ICriteria<IAHNReport>;
	selectedSavedCriteriaId: string = "";
	name: string = "";
	reportKey: string = "All";
	savedCriteriaList: ICriteria<IAHNReport>[] = [];

	showResults: boolean = false;

	charTypeIds: number[] = [
		CharTypeId.All,
		CharTypeId.Property,
		CharTypeId.User,
		CharTypeId.Right,
		CharTypeId.Transaction,
		CharTypeId.Usage,
		CharTypeId.Invoice,
		CharTypeId.Amount,
		CharTypeId.Royalty,
		CharTypeId.Document,
		CharTypeId.Job,
		CharTypeId.Project,
		CharTypeId.Inventory,
	];

	validChildren: ICharacteristicType[] = [];
	validParents: ICharacteristicType[] = [];

	// defaults for datetime fields
	dateVal;
	errors = {
		date: false,
		period: false
	};
	minDate = {}; //{year: 2023, month: 5, day: 1}
	maxDate = {}; //{year: 2023, month: 7, day: 1}

	dataSelectStrategy: CommonDataSelectStrategy<IAuditHistoryNavigatorResponse, number, IAuditHistoryNavigatorResponse>;
	dataSource: CommonGridDataSource<IAuditHistoryNavigatorResponse>;
	private readonly columns: GridColumn<IAuditHistoryNavigatorResponse>[] = [
		{
			key: "auditDate",
			headerName: "Audit Date",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.auditDate
		},
		{
			key: "userId",
			headerName: "User ID",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.userId
		},
		{
			key: "userName",
			headerName: "User",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.userName
		},
		{
			key: "charTypeLabel",
			headerName: "Record Type",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.charTypeLabel
		},
		{
			key: "charTemplateLabel",
			headerName: "Record Template",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.charTemplateLabel
		},
		{
			key: "recordId",
			headerName: "Record ID",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.recordId
		},
		{
			key: "recordTitle",
			headerName: "Record Title",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.recordTitle
		},
		{
			key: "parentModule",
			headerName: "Parent Module",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.parentModule
		},
		{
			key: "auditType",
			headerName: "Audit On",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => {
				switch (x.auditType) {
					case 1:
						return "Workflow"
					case 2:
						return "Field"
					case 3:
						return "Association"
				}
			}
		},
		{
			key: "changedColumn",
			headerName: "Field Changed",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.changedColumn
		},
		{
			key: "operationLabel",
			headerName: "Operation Type",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.operationLabel,
		},
		{
			key: "oldValue",
			headerName: "Old Value",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => {
				return this.parseJsonValue(x.oldValue);
			}
		},
		{
			key: "newValue",
			headerName: "New Value",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => {
				return this.parseJsonValue(x.newValue);
			}
		},
		{
			key: "prcLabel",
			headerName: "WF Process",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.prcLabel
		},
		{
			key: "actLabel",
			headerName: "WF Action",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.actLabel
		},
		{
			key: "parentCharTypeLabel",
			headerName: "Parent Type",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.parentCharTypeLabel
		},
		{
			key: "parentRecId",
			headerName: "Parent ID",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.parentRecId == 0 ? "" : x.parentRecId
		},
		{
			key: "parentRecTitle",
			headerName: "Parent Title",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.parentRecTitle
		},
		{
			key: "childCharTypeLabel",
			headerName: "Child Type",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.childCharTypeLabel
		},
		{
			key: "childRecId",
			headerName: "Child ID",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.childRecId == 0 ? "" : x.childRecId
		},
		{
			key: "childRecTitle",
			headerName: "Child Title",
			renderer: "text",
			width: "minmax(auto, 300px)",
			getCellData: (x) => x.childRecTitle
		},
	]

	private initialized = false;
	constructor(
		public auditHistoryNavigatorService: AuditHistoryNavigatorService,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _router: Router,
		private readonly _oneConfig: OneConfigService,
		private readonly _modalService: ModalService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _progressService: ProgressService,
		private readonly _searchService: SearchService,
		private readonly _reportService: ReportService,
		private readonly _entityConfigService: EntityConfigService,
	) {
		this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
		this.availableWorkflowStatuses = [this.nullStatus];
		this.availableWorkflowActions = [this.nullWorkflowAction];
		const sub = this._activatedRoute.queryParams
			.pipe(
				tap((params) => {
					if (!this.initialized) {
						this.setStateFromUrl(params);
						this.initialized = true;
					}
				})
			).subscribe();

		this._subs.push(sub);

	}
	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	ngOnInit(): void {

		this.dataSelectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<IAuditHistoryNavigatorResponse, number, IAuditHistoryNavigatorResponse>(doc => doc.rowId, doc => doc);



		const sub = this._router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			switchMap(() => this._activatedRoute.queryParams),
			tap((params) => {
				this.setStateFromUrl(params);
			}))
			.subscribe();



		this._subs.push(sub);




		//need to get unique id of audit history action to prevent duplicate rows
		this.dataSource = this._gridDataSourceBuilder.searchGridDataSource<IAuditHistoryNavigatorResponse, unknown>(row => row.rowId)
			.setPaging({ pageSize: 25 })
			.setSorting({ sortKey: "auditDate", sortDir: 0 })
			.setColumns(this.columns)
			.withDataSelectStrategy(this.dataSelectStrategy)
			.withFetchFn((ds) => {
				this.criteriaRequest.rowsPerPage = ds.pageSize$.value;
				this.criteriaRequest.pageOffset = Math.floor(ds.rowOffset$.value / ds.pageSize$.value) + 1;
				return this.auditHistoryNavigatorService.runAuditHistory(this.criteriaRequest).pipe(
					tap(results => {
					}),
					map((results) => ({
						rowData: results,
						rowCount: results.length > 0 ? results[0].totalCount : results.length
					}))
				);
			});
		this.getSavedCriteria();
	}

	saveCriteria() {
		const saveReportCriteriaSub = this._reportService.saveReportCriteria(this.reportNameSpace,
			this.reportKey,
			{
				reportParameters: this.criteriaRequest,
				savedReportName: this.name
			},
			ISGlobal.No)
			.subscribe((result) => {
				this.getSavedCriteria(this.name);
			});
		this._subs.push(saveReportCriteriaSub);
	}
	updateSavedCriteria(selectedCriteriaId) {
		if (selectedCriteriaId) {
			this.selectedSavedCriteria.json = JSON.stringify({
				reportParameters: this.criteriaRequest,
				savedReportName: this.name
			});
			const sub = this._reportService.updateReportCriteria(this.selectedSavedCriteria).subscribe(result => {
				this.getSavedCriteria(this.name);
			});
			this._subs.push(sub);
		}
	}

	canResave() {
		return this.selectedSavedCriteriaId && this.selectedSavedCriteria.reportName === this.name;
	}

	getSavedCriteria(selectOnLoad: string = "") {
		const getSavedReportsSub = this._reportService.getSavedReports<IAHNReport>(this.reportNameSpace, this.reportKey, ISGlobal.No)
			.subscribe((result) => {
				if (!result) {
					return;
				}

				this.savedCriteriaList = result
					.filter((x) => x.reportName)
					.sort((a, b) => a.reportName.localeCompare(b.reportName));

				if (!isEmpty(selectOnLoad)) {
					const selected = this.savedCriteriaList.find(x => x.reportName === selectOnLoad);
					if (selected) {
						this.reportCriteriaSelected(selected);
					}
				}
			});

		this._subs.push(getSavedReportsSub);
	}

	reportCriteriaSelected(event) {
		if (this.selectedSavedCriteriaId === event.id) {
			this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
			this.selectedSavedCriteria = null;
			this.selectedSavedCriteriaId = null;
		} else {
			this.criteriaRequest = event.report.reportParameters;
			this.selectedCharType = this.criteriaRequest.charTypeId;
			this.selectedTemplateId = this.criteriaRequest.templateId;
			if (this.selectedCharType > 0) {
				this.loadTemplates();
				this.loadAssociationOptions();
			}
			if (this.criteriaRequest.recordIds.length > 0) {
				this.getRecords(this.selectedCharType, this.criteriaRequest.recordIds);
			}
			if (this.criteriaRequest.userIds.length > 0) {
				this.getUsers(this.criteriaRequest.userIds);
			}
			if (this.criteriaRequest.fieldIds.length > 0) {
				this.getSourceFields(this.selectedCharType);
			}
			if (this.criteriaRequest.workflowSelection != null) {
				this.loadWorkflowOptions(this.selectedTemplateId);
				this.workflowOptionsReady = true;
			}
			this.selectedSavedCriteria = event;
			this.selectedSavedCriteriaId = event.id;
			this.name = event.reportName;
		}
		this.onCriteriaUpdated();
	}

	deleteSavedCriteria(removeReportCriteria: ICriteria<any>) {
		const confirmationSub = this._modalService.confirm(`Delete Selected Report Criteria ${removeReportCriteria.reportName}`, "<h4>Are you sure you want to perform this Delete?</h4>", "Yes")
			.subscribe(performDelete => {
				if (performDelete) {
					const deleteReportCriteriaSub = this._reportService.deleteReportCriteriaBulk([removeReportCriteria.id])
						.subscribe((result) => {
							this.getSavedCriteria();

							if (this.selectedSavedCriteria?.id === removeReportCriteria.id) {
								this.selectedSavedCriteria = null;
								this.selectedSavedCriteriaId = "";
								this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
								this.onCriteriaUpdated();
							}
						});
					this._subs.push(deleteReportCriteriaSub);
				}
			});
		this._subs.push(confirmationSub);
	}

	deleteAllSavedCriteria() {
		const confirmationSub = this._modalService.confirm(`Delete All Saved Report Criteria`, "<h4>Are you sure you want to delete all saved report criteria?</h4>", "Yes")
			.subscribe(performDelete => {
				if (performDelete) {
					const deleteReportCriteriaSub = this._reportService.deleteAllReportCriteria(this.reportNameSpace,
						this.reportKey)
						.subscribe((result) => {
							this.getSavedCriteria();
							this.name = "";
							this.selectedSavedCriteria = null;
							this.selectedSavedCriteriaId = "";
							this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
							this.onCriteriaUpdated();
						});
					this._subs.push(deleteReportCriteriaSub);
				}
			});
		this._subs.push(confirmationSub);
	}

	public onCriteriaUpdated() {
		this.sendStateToUrl(this.criteriaRequest);
	}

	private setStateFromUrl(params: Params) {
		this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
		Object.keys(params).forEach((key) => {
			if (key == "charTypeId" && params[key] > -1) {
				this.criteriaRequest[key] = +params[key];
				this.selectedCharType = +params[key];
				if (+params["templateId"] > -1) {
					this.selectedTemplateId = +params["templateId"];
					this.criteriaRequest.templateId = this.selectedTemplateId;
					this.loadWorkflowOptions(this.selectedTemplateId);
				}
				this.loadTemplates();
				this.loadAssociationOptions();
			} else if (key == "recordIds" && params[key]) {
				this.criteriaRequest.recordIds = params[key].length > 0 ? params[key].split(",").map(x => +x) : [];
				this.getRecords(this.criteriaRequest.charTypeId, this.criteriaRequest.recordIds);
			} else if (key == "templateId" || key == "childCharTypeId" || key == "parentCharTypeId" || key == "workflowFromStepId" || key == "workflowToStepId" || key == "workflowActionId") {
				this.criteriaRequest[key] = +params[key];
			} else if (key == "userIds" && params[key]) {
				this.criteriaRequest.userIds = params[key].length > 0 ? params[key].split(",").map(x => +x) : [];
				this.getUsers(this.criteriaRequest.userIds);
			} else if (key == "fieldIds" && params[key]) {
				this.criteriaRequest.fieldIds = params[key].length > 0 ? params[key].split(",").map(x => +x) : [];
				this.getSourceFields(this.criteriaRequest.charTypeId);
			} else if (params[key] == "false") {
				this.criteriaRequest[key] = false;
			} else if (params[key] == "true") {
				this.criteriaRequest[key] = true;
			} else if (params[key]) {
				this.criteriaRequest[key] = decodeURI(params[key])
			}
		})
	}

	private sendStateToUrl(criteriaRequest: IAuditHistoryNavigatorCriteriaRequest) {
		const params = this.getQueryParamsFromCriteriaRequest(criteriaRequest);
		this._router.navigate([], {
			queryParams: params
		});
	}

	private getQueryParamsFromCriteriaRequest(criteriaRequest: IAuditHistoryNavigatorCriteriaRequest): Params {
		const params: Params = {};

		Object.keys(criteriaRequest).forEach((key: keyof IAuditHistoryNavigatorCriteriaRequest) => {
			if (key != "rowsPerPage" && key != "pageOffset") {
				if (key == "userIds" && criteriaRequest.userIds && criteriaRequest.userIds.length > 0) {
					params[key] = criteriaRequest.userIds.join(",");
				} else if (key == "recordIds" && criteriaRequest.recordIds && criteriaRequest.recordIds.length > 0) {
					params[key] = criteriaRequest.recordIds.join(",");
				} else if (key == "fieldIds" && criteriaRequest.fieldIds && criteriaRequest.fieldIds.length > 0) {
					params[key] = criteriaRequest.fieldIds.join(",");
				} else if (key == "startDate" || key == "endDate") {
					const date = new Date(criteriaRequest[key]);
					// This is wild and related to https://github.com/ng-bootstrap/ng-bootstrap/issues/4729 and
					// https://github.com/rightsline/rightsline/pull/8700/files and https://rightsline.atlassian.net/browse/APP-9233
					// Part of the issue here is `toISOString` converts to UTC
					if (date.getTimezoneOffset() < 0) {
						const timeOffset = date.getTime() - date.getTimezoneOffset() * 60000;
						date.setTime(timeOffset);
					}
					params[key] = date.toISOString().substring(0, 10);
				} else if (criteriaRequest[key] && criteriaRequest[key] != "-1") {
					params[key] = criteriaRequest[key]
				}
			}
		});

		return params;
	}

	private getRecords(charTypeId: number, recordIds: number[]) {
		const sub = this._searchService.getTitles(charTypeId, recordIds)
			.subscribe((resp) => {
				this.selectedRecords = [];
				resp.forEach((val, key) => {
					this.selectedRecords.push({
						recordId: key,
						title: val
					})
				});
			});
		this._subs.push(sub);
	}

	private getUsers(userIds: number[]) {
		const sub = this._searchService.getTitles(CharTypeId.User, userIds)
			.subscribe((resp) => {
				this.selectedUsers = [];
				resp.forEach((val, key) => {
					this.selectedUsers.push({
						recordId: key,
						title: val
					})
				});
			});
		this._subs.push(sub);
	}

	private getSourceFields(charTypeId: number) {
		const sub = this._entityConfigService.getSourceFields(charTypeId, 1)
			.subscribe((resp) => {
				this.selectedFields = [];
				resp.forEach(value => {
					if (this.criteriaRequest.fieldIds.includes(value.sourceFieldID)) {
						this.selectedFields.push(value);
					}
				})
			})
		this._subs.push(sub);
	}

	updateWidth(width: number) {
		const sidePanelElement = document.querySelector(".data-query__sidenav");
		const reportElement = document.querySelector(".data-query__report");

		if (width === this.sidePanelWidthOpen) {
			sidePanelElement.classList.add("open");
			sidePanelElement.classList.remove("close");
			reportElement.classList.add("open");
			reportElement.classList.remove("close");
		} else {
			sidePanelElement.classList.add("close");
			sidePanelElement.classList.remove("open");
			reportElement.classList.add("close");
			reportElement.classList.remove("open");
		}
		this.sidePanelWidth = width;
		this.translateXValue = "translateX(" + (this.sidePanelWidth - this.sidePanelWidthOpen) + "px )";
	}

	// Datatypes methods
	updateFieldValue(event: any, fieldType: string) {
		switch (fieldType) {
			case "associationToChildCreated":
			case "associationToChildDeleted":
			case "associationToParentCreated":
			case "associationToParentDeleted":
			case "workflowCreatedState":
			case "workflowDeletedState":
			case "allWorkflowStatus":
			case "fieldValueAdded":
			case "fieldValueDeleted":
			case "fieldValueUpdated":
				this.criteriaRequest[fieldType] = !this.criteriaRequest[fieldType];
				break;
		}
		this.onCriteriaUpdated();
	}

	updateDateValue(event: DateValueChangedEvent, fieldType: string) {
		const newVal = new Date(event.dateValue);
		this.errors.date = newVal.toString() === "Invalid Date" ? true : false;
		switch (fieldType) {
			case "start":
				this.criteriaRequest.startDate = event.dateValue;
				break;
			case "end":
				this.criteriaRequest.endDate = event.dateValue;
				break;
		}
		this.onCriteriaUpdated();
	}

	loadTemplates() {
		this.templates = this._oneConfig.getTemplates(this.selectedCharType)
		if (this.selectedTemplateId > 0) {
			this.selectedTemplate = this.templates.find(x => x.templateID == this.selectedTemplateId)
		} else {
			this.selectedTemplate = null;
		}
	}

	updateRecordValue(fieldType: string) {
		switch (fieldType) {
			case "char":
				this.workflowOptionsReady = false;
				this.criteriaRequest.charTypeId = this.selectedCharType;
				this.criteriaRequest.templateId = null;
				this.criteriaRequest.parentCharTypeId = -1;
				this.criteriaRequest.childCharTypeId = -1;
				this.removeSelectedCriteriaItem("selectedRecords");
				this.selectedTemplate = null;
				this.selectedTemplateId = -1;
				this.removeSelectedCriteriaItem("selectedFields");
				this.criteriaRequest.workflowActionId = null;
				this.criteriaRequest.workflowToStepId = null;
				this.criteriaRequest.workflowFromStepId = null;
				if (this.criteriaRequest.workflowSelection != 1) {
					this.criteriaRequest.workflowSelection = null;
					this.criteriaRequest.allWorkflowStatus = false;
				}
				this.loadTemplates();
				this.loadAssociationOptions();
				break;
			case "template":
				if (this.selectedTemplate) {
					this.criteriaRequest.templateId = this.selectedTemplate.templateID
					this.loadWorkflowOptions(this.selectedTemplate.templateID);
				} else {
					this.criteriaRequest.templateId = null;
				}
				this.criteriaRequest.workflowActionId = null;
				this.criteriaRequest.workflowToStepId = null;
				this.criteriaRequest.workflowFromStepId = null;
				if (this.criteriaRequest.workflowSelection != 1) {
					this.criteriaRequest.workflowSelection = null;
					this.criteriaRequest.allWorkflowStatus = false;
				}
				this.removeSelectedCriteriaItem("selectedRecords");
				this.selectedRecordIds = [];
				break;
			case "record":
				const sub = this.recordSearch().subscribe();
				this._subs.push(sub);
				break;
			case "user":
				const user_sub = this.userSearch().subscribe();
				this._subs.push(user_sub);
		}

		this.onCriteriaUpdated();
	}

	loadWorkflowOptions(templateId: number) {
		const statuses = this._oneConfig.getTemplateStatuses(this.selectedCharType, templateId);
		this.availableWorkflowStatuses = [this.nullStatus].concat(statuses);
		const processId = this._oneConfig.getTemplateProcess(this.selectedCharType, templateId);
		const workflowProcess = this._oneConfig.getCharTypeProcesses(this.selectedCharType).find(x => x.processId === processId);
		const workflowActions = workflowProcess?.workflowActions ?? [];
		this.availableWorkflowActions = [this.nullWorkflowAction].concat(workflowActions);
		this.workflowOptionsReady = true;
	}

	loadAssociationOptions() {
		this.validChildren = this._oneConfig.getChildAssocCharTypes(this.selectedCharType);
		this.validParents = this._oneConfig.getParentAssocCharTypes(this.selectedCharType);
	}

	userSearch() {
		const options = SearchOptionsFactory.buildListPageOptions(CharTypeId.User);
		options.pageSize = 25;
		options.selectedRecordIds = this.selectedUsers.map(x => x.recordId);
		return this._modalService.searchEntity(options)
			.pipe(
				tap(response => {
					this.selectedUsers = response.recordTitles;
					this.criteriaRequest.userIds = this.selectedUsers.map(x => x.recordId);
					this.onCriteriaUpdated();
				})
			);
	}

	recordSearch() {
		if (this.selectedTemplate) {
			const options = SearchOptionsFactory.buildTemplateOptions(this.selectedCharType, this.selectedTemplate.templateID);
			options.pageSize = 25;
			options.selectedRecordIds = this.selectedRecordIds;
			return this._modalService.searchEntity(options)
				.pipe(
					tap(response => {
						this.selectedRecordIds = response.recordTitles.map(x => x.recordId);
						this.selectedRecords = response.recordTitles;
						this.criteriaRequest.recordIds = this.selectedRecordIds;
						this.onCriteriaUpdated();
					})
				);
		} else if (this.selectedCharType != -1) {
			const options = SearchOptionsFactory.buildListPageOptions(this.selectedCharType);
			options.pageSize = 25;
			options.selectedRecordIds = this.selectedRecordIds;
			return this._modalService.searchEntity(options)
				.pipe(
					tap(response => {
						this.selectedRecordIds = response.recordTitles.map(x => x.recordId);
						this.selectedRecords = response.recordTitles;
						this.criteriaRequest.recordIds = this.selectedRecordIds;
						this.onCriteriaUpdated();
					})
				);
		}
	}

	characteristicSearch() {
		const options = {
			pageSize: 25,
			selectedRecordIds: this.selectedFields.map(x => x.sourceFieldID)
		};
		const sub = this._modalService.selectSourceFields(this.selectedCharType, options)
			.pipe(
				tap(response => {
					this.selectedFields = response;
					this.criteriaRequest.fieldIds = this.selectedFields.map(x => x.sourceFieldID);
					this.onCriteriaUpdated();
				})
			).subscribe();
		this._subs.push(sub);

	}

	runReport() {
		this.showResults = true;
		const sub = this.dataSource.fetchRows().subscribe();
		this._subs.push(sub);
	}

	async exportAuditHistory(exportFormat: string) {
		const copyRequest = this.criteriaRequest;
		copyRequest.pageOffset = -1;
		this._progressService.startProgress();
		const selected = Array.from(this.dataSelectStrategy.selectedState.selectedValues.values());
		const reportUrl = new URL(window.location.href);
		const reportParams = this.getQueryParamsFromCriteriaRequest(copyRequest);
		for (const key of Object.keys(reportParams)) {
			reportUrl.searchParams.set(key, reportParams[key]);
		}
		if (selected.length > 0) {
			const exportRequest = {
				selected: selected,
				criteria: copyRequest,
				exportFormat: exportFormat,
				reportUrl: reportUrl.href
			}
			await this.auditHistoryNavigatorService.runSelectedExport(exportRequest).toPromise();
			this._progressService.endProgress();
			this._modalService.jobManager().toPromise();

		} else {
			const exportRequest = {
				criteria: copyRequest,
				exportFormat: exportFormat,
				reportUrl: reportUrl.href
			}
			await this.auditHistoryNavigatorService.runExport(exportRequest).toPromise();
			this._progressService.endProgress();
			this._modalService.jobManager().toPromise();

		}
	}

	exportSelectedAuditHistory() {
		const selected = Array.from(this.dataSelectStrategy.selectedState.selectedValues.values());
		const sub = this.auditHistoryNavigatorService.runSelectedExport(selected).subscribe();
		this._subs.push(sub);
	}

	formatByFieldType(fieldType, fieldValue) {
		switch (fieldType) {
			case "StartEndDate":
				if (!fieldValue || !fieldValue[0] || !fieldValue[1]) {
					return "";
				}

				return fieldValue[0] + " to " + fieldValue[1];

			case "Records":
				if (!fieldValue || fieldValue[0] < 0) {
					return "";
				}

				const charTypeId = fieldValue[0];
				const templateId = fieldValue[1];
				let templateName;
				let recordsVal = "Type: " + CharTypeIdUtil.toDisplayName(charTypeId);

				if (templateId !== null && this.templates && this.templates.length > 0) {
					templateName = this.templates.find(x => x.templateID === templateId).templateName;
				} else {
					templateName = "All Templates";
				}
				recordsVal += templateName ? ", Template: " + templateName : "";

				return recordsVal;

			default:
				return fieldValue;
		}
	}

	removeSelectedCriteriaItem(fieldType) {
		if (!fieldType) {
			return;
		}
		switch (fieldType) {
			case "StartEndDate":
				const currentMonth = moment().startOf("month");
				const today = moment().startOf("day");
				this.criteriaRequest.startDate = currentMonth.subtract(3, "month").format("L");
				this.criteriaRequest.endDate = today.format("L");
				break;

			case "Records":
				this.criteriaRequest.charTypeId = -1;
				this.selectedCharType = this.criteriaRequest.charTypeId;
				this.criteriaRequest.templateId = null;
				this.selectedTemplate = null;
				this.selectedRecordIds = [];
				this.criteriaRequest.recordIds = this.selectedRecordIds;
				this.criteriaRequest.workflowSelection = null;
				this.criteriaRequest.workflowFromStepId = null;
				this.criteriaRequest.workflowToStepId = null;
				this.criteriaRequest.workflowActionId = null;
				this.criteriaRequest.childCharTypeId = -1;
				this.criteriaRequest.parentCharTypeId = -1;
				break;

			case "selectedRecords":
				this.selectedRecordIds = [];
				this.criteriaRequest.recordIds = this.selectedRecordIds;
				break;

			case "selectedUsers":
				this.selectedUsers = [];
				this.criteriaRequest.userIds = this.selectedUsers.map(x => x.recordId);
				break;

			case "selectedFields":
				this.selectedFields = [];
				this.criteriaRequest.fieldIds = this.selectedFields.map(x => x.sourceFieldID);
				break;

			case "field":
				this.criteriaRequest.fieldValueAdded = false;
				this.criteriaRequest.fieldValueUpdated = false;
				this.criteriaRequest.fieldValueDeleted = false;
				break;

			case "workflow":
				this.resetAllWorkflow();
				break;

			case "association":
				this.criteriaRequest.childCharTypeId = -1;
				this.criteriaRequest.associationToChildCreated = false;
				this.criteriaRequest.associationToChildDeleted = false;
				this.criteriaRequest.parentCharTypeId = -1;
				this.criteriaRequest.associationToParentCreated = false;
				this.criteriaRequest.associationToParentDeleted = false;
				break;
		}
		this.onCriteriaUpdated();
	}

	resetAll() {
		this.criteriaRequest = this.auditHistoryNavigatorService.getDefaultCriteriaRequest();
		this.selectedTemplate = null;
		this.selectedCharType = -1;
		this.templates = [];
		this.validChildren = [];
		this.validParents = [];
		this.workflowOptionsReady = false;
		this.onCriteriaUpdated();
	}

	resetAllWorkflow(keepAllStatus: boolean = false) {
		this.criteriaRequest.workflowCreatedState = false;
		this.criteriaRequest.workflowDeletedState = false;
		this.criteriaRequest.workflowActionId = null;
		this.criteriaRequest.workflowToStepId = null;
		this.criteriaRequest.workflowFromStepId = null;
		if (this.criteriaRequest.workflowSelection != 1 || !keepAllStatus) {
			this.criteriaRequest.workflowSelection = null;
			this.criteriaRequest.allWorkflowStatus = false;
		}
		this.onCriteriaUpdated();
	}

	resetStoredStatusAction() {
		switch (this.criteriaRequest.workflowSelection) {
			case "2":
				this.criteriaRequest.workflowActionId = null;
				this.criteriaRequest.allWorkflowStatus = false;
				break;
			case "3":
				this.criteriaRequest.workflowToStepId = null;
				this.criteriaRequest.workflowFromStepId = null;
				this.criteriaRequest.allWorkflowStatus = false;
				break;
			default:
				this.criteriaRequest.workflowActionId = null;
				this.criteriaRequest.workflowToStepId = null;
				this.criteriaRequest.workflowFromStepId = null;
				this.criteriaRequest.allWorkflowStatus = true;
				break;
		}
		this.onCriteriaUpdated();
	}

	removeChip(id, type) {
		if (!id || !type) {
			return;
		}

		let index: number;

		switch (type) {
			case "selectedRecordIds":
				index = this.selectedRecordIds.findIndex(x => x === id)
				this.selectedRecordIds.splice(index, 1);
				this.criteriaRequest.recordIds = this.selectedRecordIds;
				break;

			case "selectedUsers":
				index = this.selectedUsers.findIndex(x => x.recordId === id)
				this.selectedUsers.splice(index, 1);
				this.criteriaRequest.userIds = this.selectedUsers.map(x => x.recordId);
				break;

			case "selectedFields":
				index = this.selectedFields.findIndex(x => x.sourceFieldID === id)
				this.selectedFields.splice(index, 1);
				this.criteriaRequest.fieldIds = this.selectedFields.map(x => x.sourceFieldID);
				break;
		}
		this.onCriteriaUpdated();
	}

	getRecordName(recordId: number) {
		return this.selectedRecords.find(x => x.recordId === recordId)?.title;
	}

	getUserName(userId: number) {
		return this.selectedUsers.find(x => x.recordId === userId)?.title;
	}

	getStepName(stepId: number) {
		return this.availableWorkflowStatuses.find(x => x.stepId === stepId)?.stepName;
	}

	getActionName(actionId: number) {
		return this.availableWorkflowActions.find(x => x.actionId === actionId)?.actionName;
	}

	getFieldName(fieldId: number) {
		return this.selectedFields.find(x => x.sourceFieldID === fieldId)?.sourceFieldName;
	}

	parseJsonValue(value: string) {
		let result = "";
		try {
			const parse = JSON.parse(value) as ICharDataMoneyValueNew;
			if (parse.locSym) {
				result += parse.locSym;
				if (parse.locAmt != null) {
					const parsedAmount = Number.parseFloat(parse.locAmt);
					if (!isNaN(parsedAmount)) {
						const formattedAmount = formatCurrency(parsedAmount, NumberUtil.language, "");
						result += " " + formattedAmount;
					}
				} else {
					result = value;
				}
				return result;
			} else {
				return value;
			}
		} catch (e) {
			return value;
		}
	}

}
